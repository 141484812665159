<template>
  <div class="d-flex justify-content-center">
    <b-row class="mt-3 col-12 col-lg-8 col-xl-5">
      <b-col class="col-12 text-center">
        <h1 class="mb-1">{{ $t('creator.needHelp') }}</h1>
        <p class="avenir-medium">{{ $t('creator.selectSocial') }}</p>

        <component
          :is="vue_select"
          :options="profile.networks" 
          label="network" 
          id="select-input-connection-proposal" 
          v-model="selected" 
          v-if="!loaded_connections"
        >
        <template #no-options>
          <div >
            {{$t('creator.noOption')}}
          </div>
        </template>
          <template #selected-option="data">
            <div v-if="Object.keys(data).length > 1">
              <i v-if="data.network !== 'blog' && data.network !=='twitter'" :class="`${getClassNetworkColorSelected(data.network)} icon-network-profile-connection-13 mr-1`"/>
              <b-avatar v-else  class="mr-1" size="20">
                <b-img v-if="data.network === 'blog'" :src="getClassNetworkColorSelected(data.network)" fluid class="icon-wordpress-profile-connection"/>
                <b-img v-if="data.network === 'twitter'" :src="getClassNetworkColorSelected(data.network)" fluid class="icon-twitter-profile-connection p-0"/>
              </b-avatar>
              <span>@{{(data.network === 'linkedin' ? data.name : data.username)}}</span>
            </div>
          </template>
          <template v-slot:option="data">
            <div class="option-submenu d-flex align-items-center">
                <i v-if="data.network !== 'blog' && data.network !=='twitter'" :class="`${getClassNetworkColor(data.network)} icon-network-profile-connection mr-1`"/>
                <b-avatar v-else size="42" class="mr-1">
                  <b-img v-if="data.network === 'blog'" :src="getClassNetworkColor(data.network)" fluid class="icon-wordpress-profile-connection"/>
                  <b-img v-if="data.network === 'twitter'" :src="getClassNetworkColor(data.network)" fluid class="icon-twitter-profile-connection"/>
                </b-avatar>
                <div class="text-left">
                  <strong>{{capitalize(data.network)}}</strong>
                  <span class="d-block avenir-medium"> @{{(data.network === 'linkedin' ? data.name : data.username)}}</span>
                </div>
            </div>
          </template>
        </component>
        <b-skeleton type="input" v-else class="skeleton-input-connections"></b-skeleton>
        <b-link class="d-block my-2"  @click="show_sider_network = true">
          <feather-icon icon="PlusIcon"></feather-icon>  
          {{ $t('creator.addAConnection') }}
        </b-link>
        <div><small class="text-muted">{{ $t('creator.connectionDisclam') }}</small></div>
        <b-col class="col-12 d-flex justify-content-center">
          <b-button variant="blue-button-next-step" class="blue-button-next-step my-2" :disabled="typeof selected === 'string'" @click="save()">{{proposal ? $t('creator.savePreview') : $t('creator.butonNext')}}</b-button>
        </b-col>
      </b-col>
    </b-row>
    <sider-network 
      class="open-sidebar-search"
      @close_siderbar="closeSiderNetwork"
      @updated_network="updateNetworks"
      :show_sider_network="show_sider_network"
      v-if="show_sider_network"
      />
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BAvatar,
  BImg,
  BLink,
  BSkeleton,
} from 'bootstrap-vue';
import service_others from '@/services/others';
import { getClassNetworkColor } from '@/libs/utils/icons'
import { capitalize } from '@/libs/utils/formats'
import { setuserData } from '@/libs/utils/user'
import proposals_services from '@/services/proposals';

export default {
  name: 'enrichmentConnection',
  components: {
    BRow,
    BCol,
    BButton,
    BAvatar,
    BLink,
    BImg,
    BSkeleton,
    siderNetwork: () => import('@/views/pages/proposals/stepper/siderNetwork.vue'),
  },
  data() {
    return {
      capitalize,
      getClassNetworkColor,
      show_sider_network: false,
      selected: this.$t('creator.chooseConnection'),
      profile: {
        networks: []
      },
      steps: null,
      index_actived: 0,
      selected_radio: null,
      vue_select: null,
      loaded_connections: false,
    }
  },
  computed: {
    proposal() {
      return this.$route.params.proposal
    }
  },
  created() {
    this.getProfile()
  },
  async mounted() {
    this.vue_select = (await import('vue-select')).default;
  },
  methods: {
    updateNetworks(profile) {
      this.profile.networks.unshift(profile)
    },
    closeSiderNetwork() {
      this.show_sider_network = false
    },
    getData() {
      this.steps = JSON.parse(localStorage.getItem('steps'));
      if (this.steps.network_selected) {
        this.selected = this.steps.network_selected;        
      }
    },
    save() {
      if (this.proposal) {
        proposals_services.resetProposal(this.proposal.uuid).then(() => {
          const data = {
            user_network_uuid: this.selected.uuid,
            work_type: this.steps.work_type.value,
            content_type: this.steps.content_type.value,
          }
          proposals_services.updateProposal(this.proposal.uuid, data).then((response_updated) => {
            const deal = {
              price: 50,
              title: this.$t(`dealsTitles.${this.proposal.work_type}_${this.proposal.content_type}`),
              description: this.$t('creator.anyDescription'),
              features: ['share_your_content', 'create_custom_content', 'ship_me_your_product', 'brand_mention', 'product_placement'],
              days_delivery: 15,
              revision: 2,
              licensing: 'sponsored_social_content'
            }
            proposals_services.addOptionOrRequest(response_updated.uuid, {deals: [deal]}, 'deal').then((response) => {
              this.$emit('reset_proposal', response)
              localStorage.removeItem('steps');
            });
          })
        }) 
      } else {
        this.steps['network_selected'] = this.selected;
        localStorage.setItem('steps', JSON.stringify(this.steps));
        this.$router.push({name: 'steps_proposal', params: {step: 'deals'}});
      }
    },
    getProfile() {
      this.loaded_connections = true;
      const user = JSON.parse(localStorage.userData);
      service_others.getProfile().then((response) => {
        this.loaded_connections = false;
        this.profile = response.user;
        setuserData(response, user.ability);
        this.getData()
      });
    },
    getClassNetworkColorSelected(network) {
      if (network === 'facebook') return 'icomoon-Facebook-2 facebook-border new-border'
      if (network === 'instagram') return 'icomoon-instagram1 instagram-border new-border'
      if (network === 'linkedin') return 'icomoon-Linkedin linkedin-border new-border'
      if (network === 'twitch') return 'iconNetwork-twitch twitch-border new-border'
      if (network === 'twitter') return require("@/assets/images/icons/twitter-x.svg")
      if (network === 'youtube') return 'icomoon-youtube youtube-border new-border'
      if (network === 'tiktok') return 'icomoon-tiktok tiktok-border new-border'
      if (network === 'spotify') return 'iconNetwork-spotify spotify-border new-border'
      if (network === 'pinterest') return 'icomoon-pinterest2 pinterest-border new-border'
      if (network === 'blog') return require("@/assets/images/icons/wordpress-icon.svg")
      if (network === 'snapchat') return 'iconNetwork-snapchat-ghost snapchat-border new-border'
    }
  },
}
</script>
<style scoped>
.skeleton-input-connections {
  height: 52.7px;
}
.icon-wordpress-profile-connection {
  background: white;
  width: 100% !important;
  height: 100% !important;
}
.icon-twitter-profile-connection {
  background: black;
  padding: 6px;
}
.icon-network-profile-connection {
  font-size: 24px;
  color: white;
}
.icon-network-profile-connection-13 {
  font-size: 15px;
  color: white;
}
.new-border {
  padding: 3px;
  border-radius: 100%;
}
</style>
<style>
.vs--single .vs__dropdown-toggle {
  padding: 0.7em;
}
.vs__dropdown-option--selected {
  background:  #7367f0 !important;
  color: white !important;
}
</style>